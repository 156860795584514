import React from "react"
import Container from "../components/Common/Layout/Container"
import SEO from "../components/Common/Seo"
import Theme from "../components/Theme"
import Header from "../components/Common/Header"
import Footer from "../components/Common/Footer"

const NotFoundPage = () => (
	<Theme>
	<Header />
	<Container>
		<SEO title="Page not found" />
		<h1>Page not found</h1>
	</Container>
	<Footer />
  </Theme>
)

export default NotFoundPage
